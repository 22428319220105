import { gt } from 'lodash';
import Link from 'next/link';
import { useScroll } from 'hooks';

const HomeLink = () => {
  const { position } = useScroll();
  const isScrolled = gt(position, 0);

  return (
    <Link id='home-link' href='/'>
      <a>
        <img
          src={isScrolled ? '/logos/atm-nha-colored.svg' : '/logos/atm-nha-white.svg'}
          alt='ATM Nhà'
          className='h-[40px] w-[132px]'
        />
      </a>
    </Link>
  );
};

export default HomeLink;
