import CardList from 'components/desktop/shared/card-list';
import ToastStack from 'components/desktop/shared/toast-stack';
import { FileWithFullUrls } from 'graphql/main/queries/get-real-estates';
import { useToast } from 'hooks';
import { isEmpty, map } from 'lodash';
import Link from 'next/link';
import { HomePageContext } from 'pages';
import { Fragment, MouseEvent, useContext } from 'react';
import { RiSuitcaseLine } from 'react-icons/ri';
import { SwiperSlide } from 'swiper/react';

const EnterpriseCardList = () => {
  const { toasts, addToast } = useToast();
  const { companiesWithPagination } = useContext(HomePageContext).pageData ?? {};
  const { companies } = companiesWithPagination ?? {};

  const handleOnClickCard = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    addToast('information', 'Chức năng đang được hoàn thiện!');
  };

  return !isEmpty(companies) ? (
    <Fragment>
      <CardList
        id='enterprise-card-list'
        spaceBetween={0}
        wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-paper rounded-[16px] space-y-[16px] py-[16px] shadow-1'
        iconComponent={<RiSuitcaseLine className='h-[22px] w-[22px] text-paper' />}
        titleComponent={
          <h2 className='text-[20px] font-[600] leading-[23px]'>Doanh nghiệp tiêu biểu</h2>
        }
        cardListComponent={map(companies, (company, companyIndex) => (
          <SwiperSlide
            key={`${company.id}-${companyIndex}`}
            className='min-w-[185.8px] max-w-[185.8px] px-[8px] py-[16px] xl:min-w-[197.4px] xl:max-w-[197.4px]'
          >
            <Link href='#'>
              <a onClick={handleOnClickCard}>
                <div className='flex items-center justify-center rounded-[8px] bg-paper px-[36px] py-[32px] shadow-23 transition duration-[500ms] ease-in-out hover:shadow-6'>
                  <img
                    src={
                      (company.logo as FileWithFullUrls)?.originalUrl ?? '/images/error-image.svg'
                    }
                    alt={company.name}
                    className='h-[72px] w-full object-contain'
                  />
                </div>
              </a>
            </Link>
          </SwiperSlide>
        ))}
      />
      <ToastStack toasts={toasts} />
    </Fragment>
  ) : null;
};

export default EnterpriseCardList;
