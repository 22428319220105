import SearchTool from 'components/desktop/home/banner/search-tool';
import Slogan from 'components/desktop/home/banner/slogan';

const Banner = () => (
  <div
    id='banner'
    className='flex h-[664px] flex-col items-center justify-center space-y-[32px] bg-[url("/images/banners/home-banner.webp")] bg-cover bg-center bg-no-repeat px-[24px]'
  >
    <Slogan />
    <SearchTool />
  </div>
);

export default Banner;
