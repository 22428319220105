import Banner from 'components/desktop/home/banner';
import BrokerCardList from 'components/desktop/home/broker-card-list';
import DepositCardList from 'components/desktop/home/deposit-card-list';
import EnterpriseCardList from 'components/desktop/home/enterprise-card-list';
import PartnerCardList from 'components/desktop/home/partner-card-list';
import ProjectCardList from 'components/desktop/home/project-card-list';
import RentCardList from 'components/desktop/home/rent-card-list';
import SaleCardList from 'components/desktop/home/sale-card-list';
import ServiceCardList from 'components/desktop/home/service-card-list';
import Footer from 'components/desktop/shared/footer';
import Header1 from 'components/desktop/shared/header1';
import { Fragment } from 'react';

const Home = () => (
  <Fragment>
    <Header1 />
    <main className='flex min-h-[1000px] min-w-[1052px] flex-col'>
      <div className='mb-[28px] flex flex-col space-y-[28px]'>
        <Banner />
        <DepositCardList />
        <ServiceCardList />
        <SaleCardList />
        <RentCardList />
        <EnterpriseCardList />
        {/* <Advertisment /> */}
        <ProjectCardList />
        <PartnerCardList />
        <BrokerCardList />
      </div>
    </main>
    <Footer />
  </Fragment>
);

export default Home;
