import { useTranslation } from 'hooks';

const Slogan = () => {
  const translation = useTranslation();

  return (
    <div id='slogan' className='flex flex-col space-y-[16px]'>
      <h1 className='text-center text-[24px] font-[500] leading-[30px] text-paper'>
        {translation.desktop.home.banner.slogan.title}
      </h1>
      <span className='cursor-default text-center text-[36px] font-[600] leading-[45px] text-paper'>
        {translation.desktop.home.banner.slogan.subtile}
      </span>
    </div>
  );
};

export default Slogan;
