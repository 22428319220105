import CardList from 'components/desktop/shared/card-list';
import RealEstateVerticalCard from 'components/desktop/shared/real-estate-vertical-card';
import { isEmpty, map } from 'lodash';
import { HomePageContext } from 'pages';
import { useContext } from 'react';
import { RiHome3Line } from 'react-icons/ri';
import { SwiperSlide } from 'swiper/react';

const DepositCardList = () => {
  const { depositRealEstatesWithPagination } = useContext(HomePageContext).pageData ?? {};
  const { items: depositRealEstates } = depositRealEstatesWithPagination ?? {};

  return !isEmpty(depositRealEstates) ? (
    <CardList
      id='deposit-card-list'
      spaceBetween={0}
      wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-paper rounded-[16px] space-y-[16px] py-[16px] shadow-1'
      iconComponent={<RiHome3Line className='h-[22px] w-[22px] text-paper' />}
      titleComponent={
        <h2 className='text-[20px] font-[600] leading-[23px]'>Bất động sản ký gửi</h2>
      }
      cardListComponent={map(depositRealEstates, (depositRealEstate, depositRealEstateIndex) => (
        <SwiperSlide
          key={`${depositRealEstate.id}-${depositRealEstateIndex}`}
          className='min-w-[309.5px] max-w-[309.5px] px-[8px] py-[16px] xl:min-w-[296.3px] xl:max-w-[296.3px]'
        >
          <RealEstateVerticalCard type='deposit' realEstate={depositRealEstate} />
        </SwiperSlide>
      ))}
    />
  ) : null;
};

export default DepositCardList;
