import CardList from 'components/desktop/shared/card-list';
import ProjectCard from 'components/shared/project-card';
import { fill, isEmpty, map } from 'lodash';
import { TbBuildingSkyscraper } from 'react-icons/tb';
import { SwiperSlide } from 'swiper/react';

const ProjectCardList = () => {
  const projects: any[] = fill(Array(12), {});

  return !isEmpty(projects) ? (
    <div className="bg-[url('/images/city.svg')] py-[26px]">
      <CardList
        id='project-card-list'
        spaceBetween={0}
        wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-transparent rounded-[16px] space-y-[16px] py-[16px] shadow-1 border border-paper'
        iconComponent={<TbBuildingSkyscraper className='h-[22px] w-[22px] text-paper' />}
        titleComponent={<h2 className='text-[20px] font-[600] leading-[23px]'>Dự án nổi bật</h2>}
        cardListComponent={map(projects, (project, projectIndex) => (
          <SwiperSlide
            key={`${project.id}-${projectIndex}`}
            className='min-w-[464px] max-w-[464px] px-[8px] py-[16px] xl:min-w-[394px] xl:max-w-[394px]'
          >
            <ProjectCard />
          </SwiperSlide>
        ))}
      />
    </div>
  ) : null;
};

export default ProjectCardList;
