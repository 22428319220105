import CardList from 'components/desktop/shared/card-list';
import RealEstateVerticalCard from 'components/desktop/shared/real-estate-vertical-card';
import { isEmpty, isEqual, map } from 'lodash';
import Link from 'next/link';
import { HomePageContext } from 'pages';
import { useContext } from 'react';
import { IoDiamondOutline } from 'react-icons/io5';
import { SwiperSlide } from 'swiper/react';

const SaleCardList = () => {
  const {
    saleRealEstatesWithPagination,
    saleInHoChiMinhRealEstatesWithPagination,
    saleInHaNoiRealEstatesWithPagination,
  } = useContext(HomePageContext).pageData ?? {};
  const { total } = saleRealEstatesWithPagination ?? {};
  const { items: saleInHoChiMinhRealEstates } = saleInHoChiMinhRealEstatesWithPagination ?? {};
  const { items: saleInHaNoiRealEstates } = saleInHaNoiRealEstatesWithPagination ?? {};

  return !isEmpty(saleInHoChiMinhRealEstates) || !isEmpty(saleInHaNoiRealEstates) ? (
    <div id='sale-card-list' className='flex flex-col'>
      <div className='mb-[-10px] overflow-hidden pb-[9px]'>
        <div className='container mx-auto min-w-[944px] max-w-[944px] xl:max-w-[1200px]'>
          <div className='flex items-center justify-between rounded-t-[16px] bg-paper pt-[16px] shadow-1'>
            <div className='flex items-center space-x-[8px]'>
              <div className='rounded-r-[50px] bg-primary px-[23px] py-[7px]'>
                <IoDiamondOutline className='h-[22px] w-[22px] text-paper' />
              </div>
              <h2 className='text-[20px] font-[600] leading-[23px]'>Bất động sản đang bán</h2>
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(saleInHoChiMinhRealEstates) && (
        <div className='mb-[-10px] overflow-hidden pb-[9px]'>
          <CardList
            id='ho-chi-minh-sale-card-list'
            spaceBetween={0}
            wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-paper space-y-[16px] pt-[28px] shadow-1'
            titleComponent={
              <h3 className='pl-[16px] text-[16px] font-[600] leading-[20px]'>TP. Hồ Chí Minh</h3>
            }
            viewMoreComponent={
              <Link href='/nha-dat-ban-thanh-pho-ho-chi-minh'>
                <a>
                  <span className='font-[600] text-primary'>Xem thêm</span>
                </a>
              </Link>
            }
            cardListComponent={map(
              saleInHoChiMinhRealEstates,
              (saleInHoChiMinhRealEstate, saleInHoChiMinhRealEstateIndex) => (
                <SwiperSlide
                  key={`${saleInHoChiMinhRealEstate.id}-${saleInHoChiMinhRealEstateIndex}`}
                  className='min-w-[309.5px] max-w-[309.5px] px-[8px] py-[16px] xl:min-w-[296.3px] xl:max-w-[296.3px]'
                >
                  <RealEstateVerticalCard
                    type={
                      saleInHoChiMinhRealEstate.isAuthorizedRe
                        ? 'deposit'
                        : isEqual(saleInHoChiMinhRealEstate.postType, 1)
                        ? 'special'
                        : isEqual(saleInHoChiMinhRealEstate.postType, 2)
                        ? 'vip'
                        : 'normal'
                    }
                    realEstate={saleInHoChiMinhRealEstate}
                  />
                </SwiperSlide>
              ),
            )}
          />
        </div>
      )}
      {!isEmpty(saleInHaNoiRealEstates) && (
        <div className='mb-[-10px] overflow-hidden pb-[9px]'>
          <CardList
            id='ha-noi-sale-card-list'
            spaceBetween={0}
            wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-paper space-y-[16px] pt-[28px] shadow-1'
            titleComponent={
              <h3 className='pl-[16px] text-[16px] font-[600] leading-[20px]'>Hà Nội</h3>
            }
            viewMoreComponent={
              <Link href='/nha-dat-ban-thanh-pho-ha-noi'>
                <a>
                  <span className='font-[600] text-primary'>Xem thêm</span>
                </a>
              </Link>
            }
            cardListComponent={map(
              saleInHaNoiRealEstates,
              (saleInHaNoiRealEstate, saleInHaNoiRealEstateIndex) => (
                <SwiperSlide
                  key={`${saleInHaNoiRealEstate.id}-${saleInHaNoiRealEstateIndex}`}
                  className='min-w-[309.5px] max-w-[309.5px] px-[8px] py-[16px] xl:min-w-[296.3px] xl:max-w-[296.3px]'
                >
                  <RealEstateVerticalCard
                    type={
                      saleInHaNoiRealEstate.isAuthorizedRe
                        ? 'deposit'
                        : isEqual(saleInHaNoiRealEstate.postType, 1)
                        ? 'special'
                        : isEqual(saleInHaNoiRealEstate.postType, 2)
                        ? 'vip'
                        : 'normal'
                    }
                    realEstate={saleInHaNoiRealEstate}
                  />
                </SwiperSlide>
              ),
            )}
          />
        </div>
      )}
      <div className='mb-[-10px] overflow-hidden pb-[9px]'>
        <div className='container mx-auto min-w-[944px] max-w-[944px] xl:max-w-[1200px]'>
          <div className='rounded-b-[16px] bg-paper px-[16px] pt-[28px] pb-[16px] shadow-1'>
            <Link href='/nha-dat-ban'>
              <a className='flex h-[38px] items-center justify-center rounded-[6px] bg-secondary p-[10px]'>
                <span className='font-[600] text-primary'>{`Xem thêm ${
                  total ? total.toLocaleString() : 'các'
                } tin bán khác`}</span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default SaleCardList;
