import { SearchForm1, searchFormDefaultValues1 } from 'forms';
import { DEFAULT_MAIN_SEARCH_TOOL_PLACEHOLDER } from 'globalConstants';
import { useFlexLazyQuery, useFlexQuery } from 'hooks';
import { gt, isEmpty, isNull, toString } from 'lodash';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { IoSearchOutline } from 'react-icons/io5';
import { MdClear } from 'react-icons/md';
import Typewriter from 'typewriter-effect';
import { SLUGERY, SlugeryData, SlugeryVariables } from 'graphql/main/queries/slugery';
import {
  GET_SUGGESTIONS,
  GetSuggestionsData,
  GetSuggestionsVariables,
  TypeOfDemand,
} from 'graphql/map/queries/get-suggestions';
import SuggestionPanel from 'components/desktop/shared/suggestion-panel';
import AreaPopover from 'components/desktop/home/banner/search-tool/for-sale-tab/area-popover';
import DistrictPopover from 'components/desktop/home/banner/search-tool/for-sale-tab/district-popover';
import PricePopover from 'components/desktop/home/banner/search-tool/for-sale-tab/price-popover';
import ProvincePopover from 'components/desktop/home/banner/search-tool/for-sale-tab/province-popover';
import RealEstateTypePopover from 'components/desktop/home/banner/search-tool/for-sale-tab/real-estate-type-popover';

const ForSaleTab = () => {
  const router = useRouter();
  const [isShowTypeWritter, setIsShowTypeWritter] = useState(false);
  const [isFocusInput, setIsFocusInput] = useState(false);
  const [placeholder, setPlaceholder] = useState(DEFAULT_MAIN_SEARCH_TOOL_PLACEHOLDER);
  const form = useForm({
    defaultValues: searchFormDefaultValues1,
  });
  const searchRegister = form.register('search');
  const searchWatch = useWatch({ control: form.control, name: 'search' });
  const provinceWatch = useWatch({ control: form.control, name: 'province' });
  const { data } = useFlexQuery<GetSuggestionsData, GetSuggestionsVariables>(
    'map',
    GET_SUGGESTIONS,
    {
      variables: {
        search: toString(searchWatch),
        typeOfDemand: TypeOfDemand.ForSale,
      },
      skip: !searchWatch,
    },
  );
  const { getSuggestions: suggestions } = data ?? {};
  const [slugery] = useFlexLazyQuery<SlugeryData, SlugeryVariables>('main', SLUGERY);

  const handleOnFocusInput = () => {
    setPlaceholder('');
    setIsShowTypeWritter(isEmpty(searchWatch));
    setIsFocusInput(true);
  };
  const handleOnBlurInput = () => {
    setPlaceholder(DEFAULT_MAIN_SEARCH_TOOL_PLACEHOLDER);
    setIsShowTypeWritter(false);
    setIsFocusInput(false);
  };
  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowTypeWritter(isEmpty(event.target.value));
  };
  const handleOnResetFilter = () => {
    form.setValue('search', undefined);
    form.setValue('realEstateTypes', undefined);
    form.setValue('province', undefined);
    form.setValue('district', undefined);
    form.setValue('price', undefined);
    form.setValue('area', undefined);
  };
  const handleOnClearInput = () => {
    form.setValue('search', undefined);
  };
  const onSubmit = async (data: SearchForm1) => {
    const isReverseArea = !isNull(data.area?.to) && gt(data.area?.from, data.area?.to);
    const isReversePrice = !isNull(data.price?.to) && gt(data.price?.from, data.price?.to);
    const slugeryData = await slugery({
      variables: {
        search: data.search,
        demandType: data.demand,
        type: data.realEstateTypes,
        address:
          data.province || data.district
            ? {
                province: data.province
                  ? { id: data.province.id, name: data.province.name }
                  : undefined,
                district: data.district
                  ? { id: data.district.id, name: data.district.name }
                  : undefined,
              }
            : undefined,
        area: data.area
          ? isReverseArea
            ? { from: data.area.to, to: data.area.from }
            : { from: data.area.from, to: data.area.to }
          : undefined,
        price: data.price
          ? isReversePrice
            ? { from: data.price.to, to: data.price.from }
            : { from: data.price.from, to: data.price.to }
          : undefined,
      },
    });
    if (slugeryData.data?.slugery?.slug) {
      const parsedUrl = queryString.parseUrl(slugeryData.data.slugery.slug);
      router.push({
        pathname: parsedUrl.url,
        query: queryString.stringify(parsedUrl.query),
      });
    }
  };

  useEffect(() => {
    form.setValue('district', undefined);
  }, [provinceWatch]);

  return (
    <FormProvider {...form}>
      <form
        id='for-sale-search-form'
        autoComplete='off'
        className='flex space-x-[20px]'
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className='flex w-full min-w-0 flex-col justify-between space-y-[16px]'>
          <div className='relative flex h-[40px] w-full items-center rounded-[8px] border border-paper bg-paper '>
            <div className='relative flex h-full w-full'>
              <input
                {...searchRegister}
                id='search'
                type='text'
                className='h-full w-full truncate bg-transparent pl-[12px] placeholder-text2'
                placeholder={placeholder}
                onFocus={handleOnFocusInput}
                onBlur={(event) => {
                  searchRegister.onBlur(event);
                  handleOnBlurInput();
                }}
                onChange={(event) => {
                  searchRegister.onChange(event);
                  handleOnChangeInput(event);
                }}
              />
              {isShowTypeWritter && (
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 1,
                    delay: 50,
                    strings: ['Vinhomes', '138 Lý Thường Kiệt', 'Biệt thự ven sông chính chủ'],
                    wrapperClassName:
                      'text-text2 absolute left-[12px] top-1/2 -translate-y-1/2 pointer-events-none',
                    cursorClassName: 'hidden',
                  }}
                />
              )}
              {Boolean(searchWatch) && (
                <button type='button' className='ml-[12px]' onClick={handleOnClearInput}>
                  <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                </button>
              )}
              <SuggestionPanel
                isShowing={isFocusInput && Boolean(searchWatch)}
                keyword={searchWatch}
                suggestions={suggestions}
              />
            </div>
            <button className='ml-[12px] flex h-full items-center space-x-[8px] rounded-r-[8px] bg-primary px-[12px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'>
              <IoSearchOutline className='min-h-[18px] min-w-[18px]' />
              <span className='whitespace-nowrap font-[600]'>Tìm kiếm</span>
            </button>
          </div>
          <div className='flex items-end space-x-[16px]'>
            <RealEstateTypePopover />
            <ProvincePopover />
            <DistrictPopover />
            <AreaPopover />
            <PricePopover />
            <button
              type='button'
              className='flex h-[40px] items-center justify-center space-x-[6px] rounded-[8px] border border-primary bg-paper px-[8px] py-[12px]'
              onClick={handleOnResetFilter}
            >
              <BsArrowCounterclockwise className='min-h-[23px] min-w-[23px]' />
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ForSaleTab;
