import CardList from 'components/desktop/shared/card-list';
import ToastStack from 'components/desktop/shared/toast-stack';
import { FileWithFullUrls } from 'graphql/main/queries/get-real-estates';
import { useToast } from 'hooks';
import { isEmpty, map } from 'lodash';
import Link from 'next/link';
import { Fragment, MouseEvent } from 'react';
import { FaRegHandshake } from 'react-icons/fa';
import { SwiperSlide } from 'swiper/react';

const PartnerCardList = () => {
  const { toasts, addToast } = useToast();
  const partners = [
    {
      id: '1',
      name: 'partner1',
      logo: {
        originalUrl: '/dummy/partner.svg',
      },
    },
    {
      id: '2',
      name: 'partner2',
      logo: {
        originalUrl: '/dummy/partner.svg',
      },
    },
    {
      id: '3',
      name: 'partner3',
      logo: {
        originalUrl: '/dummy/partner.svg',
      },
    },
    {
      id: '4',
      name: 'partner4',
      logo: {
        originalUrl: '/dummy/partner.svg',
      },
    },
    {
      id: '5',
      name: 'partner5',
      logo: {
        originalUrl: '/dummy/partner.svg',
      },
    },
    {
      id: '6',
      name: 'partner6',
      logo: {
        originalUrl: '/dummy/partner.svg',
      },
    },
  ];

  const handleOnClickCard = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    addToast('information', 'Chức năng đang được hoàn thiện!');
  };

  return !isEmpty(partners) ? (
    <Fragment>
      <CardList
        id='partner-card-list'
        spaceBetween={0}
        wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-paper rounded-[16px] space-y-[16px] py-[16px] shadow-1'
        iconComponent={<FaRegHandshake className='h-[22px] w-[22px] text-paper' />}
        titleComponent={
          <h2 className='text-[20px] font-[600] leading-[23px]'>Đối tác chiến lược</h2>
        }
        cardListComponent={map(partners, (partner, partnerIndex) => (
          <SwiperSlide
            key={`${partner.id}-${partnerIndex}`}
            className='min-w-[185.8px] max-w-[185.8px] px-[8px] py-[16px] xl:min-w-[197.4px] xl:max-w-[197.4px]'
          >
            <Link href='#'>
              <a onClick={handleOnClickCard}>
                <div className='flex items-center justify-center rounded-[8px] bg-paper px-[44px] py-[32px] shadow-23 transition duration-[500ms] ease-in-out hover:shadow-6'>
                  <img
                    src={
                      (partner.logo as FileWithFullUrls)?.originalUrl ?? '/images/error-image.svg'
                    }
                    alt={partner.name}
                    className='h-[72px] w-full object-contain'
                  />
                </div>
              </a>
            </Link>
          </SwiperSlide>
        ))}
      />
      <ToastStack toasts={toasts} />
    </Fragment>
  ) : null;
};

export default PartnerCardList;
