import CardList from 'components/desktop/shared/card-list';
import ToastStack from 'components/desktop/shared/toast-stack';
import { useToast } from 'hooks';
import { isEmpty, map } from 'lodash';
import Link from 'next/link';
import { Fragment, MouseEvent } from 'react';
import { MdSupportAgent } from 'react-icons/md';
import { SwiperSlide } from 'swiper/react';

const ServiceCardList = () => {
  const { toasts, addToast } = useToast();
  const services = [
    {
      name: 'Ký gửi bất động sản',
      image: '/images/services/service-ky-gui-bat-dong-san.webp',
    },
    {
      name: 'Miễn phí ký gửi sản phẩm giá sỉ',
      image: '/images/services/service-mien-phi-ky-gui-san-pham-gia-si.webp',
    },
    {
      name: 'Không gian quản lý bất động sản tiện lợi',
      image: '/images/services/service-khong-gian-quan-ly-bat-dong-san-tien-loi.webp',
    },
  ];

  const handleOnClickCard = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    addToast('information', 'Chức năng đang được hoàn thiện!');
  };

  return !isEmpty(services) ? (
    <Fragment>
      <CardList
        id='service-card-list'
        freeMode
        loop
        spaceBetween={0}
        wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-paper rounded-[16px] space-y-[16px] py-[16px] shadow-1'
        iconComponent={<MdSupportAgent className='h-[22px] w-[22px] text-paper' />}
        titleComponent={
          <h2 className='text-[20px] font-[600] leading-[23px]'>
            Dịch vụ của <span className="font-['Josefin_Sans'] text-[#ff3f3f]">ATM</span>{' '}
            <span className="font-['Josefin_Sans'] text-primary">NHÀ</span>
          </h2>
        }
        cardListComponent={map(services, (service, serviceIndex) => (
          <SwiperSlide
            key={serviceIndex}
            className='min-w-[382px] max-w-[382px] px-[8px] py-[16px]'
          >
            <Link href='#'>
              <a onClick={handleOnClickCard}>
                <div className='flex overflow-hidden rounded-[12px] bg-paper shadow-23 transition duration-[500ms] ease-in-out hover:shadow-6'>
                  <img
                    src={service.image}
                    alt={service.name}
                    className='h-[150px] w-full object-cover'
                  />
                </div>
              </a>
            </Link>
          </SwiperSlide>
        ))}
      />
      <ToastStack toasts={toasts} />
    </Fragment>
  ) : null;
};

export default ServiceCardList;
