import { Popover } from '@headlessui/react';
import { useConfigurationContext } from 'components/providers/configuration-provider';
import { SearchForm1 } from 'forms';
import { RangeValue } from 'graphql/main/queries/get-real-estates';
import { useTranslation } from 'hooks';
import { find, gt, isEqual, isNull, isNumber, isUndefined, map, toNumber, toString } from 'lodash';
import { ChangeEvent, MutableRefObject, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { MdClear, MdOutlineArrowDropDown } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import {
  convertNumberToVietnameseCurrencyShortString,
  numberToStringWithCommas,
  stringWithCommasToNumber,
} from 'utils';

const PricePopover = () => {
  const popoverButtonRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const translation = useTranslation();
  const { major } = useConfigurationContext();
  const prices = major?.common?.priceForRent;
  const form = useFormContext<SearchForm1>();
  const priceWatch = useWatch({ control: form.control, name: 'price' });
  const isNoCheck = isUndefined(priceWatch);
  const hasFrom = isNumber(priceWatch?.from) && !isEqual(priceWatch?.from, 0);
  const hasTo = isNumber(priceWatch?.to) && !isUndefined(priceWatch?.to);
  const isReverse = gt(priceWatch?.from, priceWatch?.to);
  const priceLabel = isNoCheck
    ? 'Tất cả'
    : priceWatch?.key
    ? (translation.major.priceForRent as any)[priceWatch.key]
    : hasFrom && hasTo
    ? isReverse
      ? `${convertNumberToVietnameseCurrencyShortString(
          toNumber(priceWatch.to),
        )} - ${convertNumberToVietnameseCurrencyShortString(toNumber(priceWatch.from))}`
      : `${convertNumberToVietnameseCurrencyShortString(
          toNumber(priceWatch.from),
        )} - ${convertNumberToVietnameseCurrencyShortString(toNumber(priceWatch.to))}`
    : hasFrom
    ? `${convertNumberToVietnameseCurrencyShortString(toNumber(priceWatch.from))} trở lên`
    : hasTo
    ? `${convertNumberToVietnameseCurrencyShortString(toNumber(priceWatch.to))} trở xuống`
    : 'Tất cả';

  const handleOnChangeFrom = (event: ChangeEvent<HTMLInputElement>) => {
    const fromValue = stringWithCommasToNumber(event.target.value);
    const foundPrice = find(
      prices,
      (price) => isEqual(price.from, fromValue) && isEqual(price.to, priceWatch?.to),
    );
    form.setValue('price', {
      ...priceWatch,
      key: foundPrice ? foundPrice.key : undefined,
      from: fromValue,
    });
  };
  const handleOnClearFromInput = () => {
    const foundPrice = find(
      prices,
      (price) => isEqual(price.from, 0) && isEqual(price.to, priceWatch?.to),
    );
    form.setValue('price', {
      ...priceWatch,
      key: foundPrice ? foundPrice.key : undefined,
      from: 0,
    });
  };
  const handleOnChangeTo = (event: ChangeEvent<HTMLInputElement>) => {
    const toValue = stringWithCommasToNumber(event.target.value);
    const foundPrice = find(
      prices,
      (price) => isEqual(price.to, toValue) && isEqual(price.from, priceWatch?.from),
    );
    form.setValue('price', {
      ...priceWatch,
      key: foundPrice ? foundPrice.key : undefined,
      to: toValue,
    });
  };
  const handleOnClearToInput = () => {
    const foundPrice = find(
      prices,
      (price) => isNull(price.to) && isEqual(price.from, priceWatch?.from),
    );
    form.setValue('price', {
      ...priceWatch,
      key: foundPrice ? foundPrice.key : undefined,
      to: null,
    });
  };
  const handleOnSelectPrice = (price: RangeValue) => {
    form.setValue('price', price);
    popoverButtonRef.current?.click();
  };
  const handleOnReset = () => {
    form.setValue('price', undefined);
    popoverButtonRef.current?.click();
  };
  const handleOnConfirm = () => {
    popoverButtonRef.current?.click();
  };

  return (
    <Popover id='price-popover' className='relative flex w-full min-w-0 flex-col space-y-[8px]'>
      <div className='flex items-center'>
        <label className='font-[600] text-paper'>Giá</label>
      </div>
      <Popover.Button
        ref={popoverButtonRef}
        type='button'
        className='flex h-[40px] items-center rounded-[8px] border border-primary bg-paper px-[12px]'
      >
        <span className='w-full truncate text-left'>{priceLabel}</span>
        <MdOutlineArrowDropDown className='min-h-[24px] min-w-[24px] text-text2' />
      </Popover.Button>
      <Popover.Panel className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[4px]'>
        <div className='w-[280px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
          <div className='flex items-center space-x-[8px] px-[16px]'>
            <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke p-[12px]'>
              <NumericFormat
                thousandSeparator
                autoComplete='off'
                placeholder='Từ'
                maxLength={14}
                value={numberToStringWithCommas(priceWatch?.from)}
                className='w-full bg-transparent placeholder-text2'
                onChange={handleOnChangeFrom}
              />
              <button
                type='button'
                className='ml-[8px] hidden group-hover:flex'
                onClick={handleOnClearFromInput}
              >
                <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
              </button>
            </div>
            <span>-</span>
            <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke p-[12px]'>
              <NumericFormat
                thousandSeparator
                autoComplete='off'
                placeholder='Đến'
                maxLength={14}
                value={numberToStringWithCommas(priceWatch?.to)}
                className='w-full bg-transparent placeholder-text2'
                onChange={handleOnChangeTo}
              />
              <button
                type='button'
                className='ml-[8px] hidden group-hover:flex'
                onClick={handleOnClearToInput}
              >
                <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
              </button>
            </div>
          </div>
          <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
            {map(prices, (price, priceIndex) => (
              <li key={`${price}-${priceIndex}`}>
                <button
                  type='button'
                  className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                  onClick={() => {
                    handleOnSelectPrice(price);
                  }}
                >
                  <div
                    className={`h-[20px] w-[20px] rounded-full border ${
                      isEqual(priceWatch, price)
                        ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                        : 'border-stroke'
                    }`}
                  />
                  <span className='line-clamp-1'>
                    {(translation.major.priceForRent as any)[toString(price.key)]}
                  </span>
                </button>
              </li>
            ))}
          </ul>
          <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
            <button
              type='button'
              className='flex items-center justify-center space-x-[6px]'
              onClick={handleOnReset}
            >
              <BsArrowCounterclockwise className='min-h-[16px] min-w-[16px]' />
              <span>Đặt lại</span>
            </button>
            <button
              type='button'
              className='flex h-[38px] items-center justify-center space-x-[6px] rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
              onClick={handleOnConfirm}
            >
              <span>Xác nhận</span>
            </button>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default PricePopover;
