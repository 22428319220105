import { useAuthenticationContext } from 'components/providers/authentication-provider';
import { ACCOUNT_DOMAIN, SOCIAL_DOMAIN } from 'globalConstants';
import { useScroll } from 'hooks';
import { gt, isEmpty, map } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, MouseEvent } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { FileWithFullUrls } from '../../../../../graphql/main/queries/get-real-estates';

const Toolbar = () => {
  const router = useRouter();
  const { position } = useScroll();
  const isScrolled = gt(position, 0);
  const { currentUser, signOut } = useAuthenticationContext();
  const navigations = [
    {
      name: 'Nhà bán',
      pathname: '/nha-dat-ban',
      extraProps: {},
      children: [
        {
          name: 'Bán nhà mặt phố',
          pathname: '/ban-nha-mat-pho',
          extraProps: {},
        },
        {
          name: 'Bán nhà riêng',
          pathname: '/ban-nha-rieng',
          extraProps: {},
        },
        {
          name: 'Bán biệt thự, nhà liền kề',
          pathname: '/ban-biet-thu-nha-lien-ke',
          extraProps: {},
        },
        {
          name: 'Bán cao ốc, văn phòng',
          pathname: '/ban-cao-oc-van-phong',
          extraProps: {},
        },
        {
          name: 'Bán khách sạn',
          pathname: '/ban-khach-san',
          extraProps: {},
        },
        {
          name: 'Bán nhà xưởng, kho, kiot',
          pathname: '/ban-nha-xuong-kho-kiot',
          extraProps: {},
        },
        {
          name: 'Bán đất nền',
          pathname: '/ban-dat-nen',
          extraProps: {},
        },
        {
          name: 'Bán căn hộ chung cư',
          pathname: '/ban-can-ho-chung-cu',
          extraProps: {},
        },
        {
          name: 'Bán shophouse',
          pathname: '/ban-shophouse',
          extraProps: {},
        },
        {
          name: 'Bán penthouse',
          pathname: '/ban-penthouse',
          extraProps: {},
        },
        {
          name: 'Bán condotel',
          pathname: '/ban-condotel',
          extraProps: {},
        },
        {
          name: 'Bán trang trại, khu nghỉ dưỡng',
          pathname: '/ban-trang-trai-khu-nghi-duong',
          extraProps: {},
        },
        {
          name: 'Bán nhà đất khác',
          pathname: '/ban-nha-dat-khac',
          extraProps: {},
        },
      ],
    },
    {
      name: 'Nhà thuê',
      pathname: '/nha-dat-cho-thue',
      extraProps: {},
      children: [
        {
          name: 'Cho thuê nhà mặt phố',
          pathname: '/cho-thue-nha-mat-pho',
          extraProps: {},
        },
        {
          name: 'Cho thuê nhà riêng',
          pathname: '/cho-thue-nha-rieng',
          extraProps: {},
        },
        {
          name: 'Cho thuê biệt thự, nhà liền kề',
          pathname: '/cho-thue-biet-thu-nha-lien-ke',
          extraProps: {},
        },
        {
          name: 'Cho thuê cao ốc, văn phòng',
          pathname: '/cho-thue-cao-oc-van-phong',
          extraProps: {},
        },
        {
          name: 'Cho thuê khách sạn',
          pathname: '/cho-thue-khach-san',
          extraProps: {},
        },
        {
          name: 'Cho thuê nhà xưởng, kho, kiot',
          pathname: '/cho-thue-nha-xuong-kho-kiot',
          extraProps: {},
        },
        {
          name: 'Cho thuê đất nền',
          pathname: '/cho-thue-dat-nen',
          extraProps: {},
        },
        {
          name: 'Cho thuê căn hộ chung cư',
          pathname: '/cho-thue-can-ho-chung-cu',
          extraProps: {},
        },
        {
          name: 'Cho thuê shophouse',
          pathname: '/cho-thue-shophouse',
          extraProps: {},
        },
        {
          name: 'Cho thuê penthouse',
          pathname: '/cho-thue-penthouse',
          extraProps: {},
        },
        {
          name: 'Cho thuê condotel',
          pathname: '/cho-thue-condotel',
          extraProps: {},
        },
        {
          name: 'Cho thuê phòng trọ',
          pathname: '/cho-thue-phong-tro',
          extraProps: {},
        },
        {
          name: 'Cho thuê nhà đất khác',
          pathname: '/cho-thue-nha-dat-khac',
          extraProps: {},
        },
      ],
    },
    {
      name: 'Dự án',
      pathname: '/du-an',
      extraProps: {},
    },
    {
      name: 'Bản đồ',
      pathname: '/ban-do',
      extraProps: {},
    },
    {
      name: 'Định giá',
      pathname: '/dinh-gia',
      extraProps: {},
    },
    {
      name: 'Tin tức',
      pathname: '/blog',
      extraProps: {},
      children: [
        {
          name: 'Kiến thức bất động sản',
          pathname: '/blog/kien-thuc-bat-dong-san',
          extraProps: {},
          children: [
            {
              name: 'Các loại bất động sản',
              pathname: '/blog/cac-loai-bat-dong-san',
              extraProps: {},
            },
            {
              name: 'So sánh các loại bất động sản',
              pathname: '/blog/so-sanh-cac-loai-bat-dong-san',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Thông tin thị trường',
          pathname: '/blog/thong-tin-thi-truong',
          extraProps: {},
          children: [
            {
              name: 'Tin tức BĐS',
              pathname: '/blog/tin-tuc-bds',
              extraProps: {},
            },
            {
              name: 'Proptech Việt Nam',
              pathname: '/blog/proptech-viet-nam',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Phong thủy nhà ở',
          pathname: '/blog/phong-thuy-nha-o',
          extraProps: {},
          children: [
            {
              name: 'Phong thủy phòng ngủ',
              pathname: '/blog/phong-thuy-phong-ngu',
              extraProps: {},
            },
            {
              name: 'Phong thủy phòng khách',
              pathname: '/blog/phong-thuy-phong-khach',
              extraProps: {},
            },
            {
              name: 'Phong thủy phòng tắm',
              pathname: '/blog/phong-thuy-phong-tam',
              extraProps: {},
            },
            {
              name: 'Phong thủy phòng bếp',
              pathname: '/blog/phong-thuy-phong-bep',
              extraProps: {},
            },
            {
              name: 'Phong thủy cửa chính, sân vườn',
              pathname: '/blog/phong-thuy-cua-chinh-san-vuon',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Phong thủy nhà đất',
          pathname: '/blog/phong-thuy-nha-dat',
          extraProps: {},
          children: [
            {
              name: 'Phong thủy vị trí',
              pathname: '/blog/phong-thuy-vi-tri',
              extraProps: {},
            },
            {
              name: 'Phong thủy hình dạng đất',
              pathname: '/blog/phong-thuy-hinh-dang-dat',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Tư vấn đầu tư bất động sản',
          pathname: '/blog/tu-van-dau-tu-bat-dong-san',
          extraProps: {},
          children: [
            {
              name: 'So sánh đầu tư BĐS và các kênh khác',
              pathname: '/blog/so-sanh-dau-tu-bds-va-cac-kenh-khac',
              extraProps: {},
            },
            {
              name: 'Vị trí bất động sản',
              pathname: '/blog/vi-tri-bat-dong-san',
              extraProps: {},
            },
            {
              name: 'Loại hình BĐS đầu tư',
              pathname: '/blog/loai-hinh-bds-dau-tu',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Phong thủy theo tuổi',
          pathname: '/blog/phong-thuy-theo-tuoi',
          extraProps: {},
          children: [
            {
              name: 'Tý',
              pathname: '/blog/ty',
              extraProps: {},
            },
            {
              name: 'Sửu',
              pathname: '/blog/suu',
              extraProps: {},
            },
            {
              name: 'Dần',
              pathname: '/blog/dan',
              extraProps: {},
            },
            {
              name: 'Mão',
              pathname: '/blog/mao',
              extraProps: {},
            },
            {
              name: 'Thìn',
              pathname: '/blog/thin',
              extraProps: {},
            },
            {
              name: 'Tỵ',
              pathname: '/blog/ty-1',
              extraProps: {},
            },
            {
              name: 'Ngọ',
              pathname: '/blog/ngo',
              extraProps: {},
            },
            {
              name: 'Mùi',
              pathname: '/blog/mui',
              extraProps: {},
            },
            {
              name: 'Thân',
              pathname: '/blog/than',
              extraProps: {},
            },
            {
              name: 'Dậu',
              pathname: '/blog/dau',
              extraProps: {},
            },
            {
              name: 'Tuất',
              pathname: '/blog/tuat',
              extraProps: {},
            },
            {
              name: 'Hợi',
              pathname: '/blog/hoi',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Vay vốn mua bất động sản',
          pathname: '/blog/vay-von-mua-bat-dong-san',
          extraProps: {},
        },
        {
          name: 'Thủ tục pháp lý bất động sản',
          pathname: '/blog/thu-tuc-phap-ly-bat-dong-san',
          extraProps: {},
          children: [
            {
              name: 'Kiểm tra quy hoạch',
              pathname: '/blog/kiem-tra-quy-hoach',
              extraProps: {},
            },
            {
              name: 'Lưu ý khi mua bán BĐS',
              pathname: '/blog/luu-y-khi-mua-ban-bds',
              extraProps: {},
            },
          ],
        },
      ],
    },
    {
      name: 'Mạng xã hội',
      pathname: SOCIAL_DOMAIN,
      extraProps: {
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    },
  ];
  const userMenu = [
    {
      name: 'Tin đã đăng',
      pathname: '/quan-ly-tin-rao',
    },
    {
      name: 'Tin đã lưu',
      pathname: '/quan-ly-tin-rao/tin-rao-da-luu',
    },
    {
      name: 'Lịch xem nhà',
      hasBorder: true,
      pathname: '/lich-xem-nha',
    },
    {
      name: 'Quản lý tài chính',
      hasBorder: true,
      pathname: '/quan-ly-tai-chinh',
    },
    {
      name: 'Thông tin cá nhân',
      pathname: '/thong-tin-cua-toi',
    },
    {
      name: 'Đổi mật khẩu',
      hasBorder: true,
      pathname: '/thong-tin-cua-toi/doi-mat-khau',
    },
  ];

  const hanldeOnClickChildNavigation = (event: MouseEvent<HTMLSpanElement>, pathname: string) => {
    event.preventDefault();
    router.push({
      pathname,
    });
  };
  const handleOnSignOut = () => {
    signOut?.();
    router.push('/dang-nhap');
  };

  return (
    <div id='toolbar' className='flex items-center space-x-[28px]'>
      {isEmpty(navigations) || (
        <nav>
          <ul className='flex items-center space-x-[16px]'>
            {map(navigations, (navigation, navigationIndex) => (
              <li key={navigationIndex} className='group/navigation relative'>
                <Link href={navigation.pathname} passHref>
                  <a
                    {...navigation.extraProps}
                    className={`flex items-center ${isScrolled ? 'text-text' : 'text-paper'}`}
                  >
                    <span className='font-[600]'>{navigation.name}</span>
                    {navigation.children && <HiChevronDown className='h-[22px] w-[22px]' />}
                  </a>
                </Link>
                {isEmpty(navigation.children) || (
                  <div className='absolute left-1/2 flex w-max origin-[top_center] -translate-x-1/2 scale-0 opacity-0 transition duration-[200ms] ease-in-out group-hover/navigation:scale-100 group-hover/navigation:opacity-100'>
                    <ul className='mt-[12px] flex flex-col rounded-[4px] bg-paper py-[4px] shadow-8'>
                      {map(navigation.children, (navigation, navigationIndex) => (
                        <li key={navigationIndex}>
                          <Link href={navigation.pathname} passHref>
                            <a
                              {...navigation.extraProps}
                              className='group/navigation-child relative flex items-center justify-between space-x-[12px] whitespace-nowrap bg-transparent py-[8px] px-[12px] hover:bg-secondary'
                            >
                              <span>{navigation.name}</span>
                              {isEmpty(navigation.children) || (
                                <Fragment>
                                  <HiChevronRight className='h-[22px] w-[22px]' />
                                  <div className='absolute top-0 left-[calc(100%-12px)] flex hidden flex-col rounded-[4px] pl-[4px] group-hover/navigation-child:flex'>
                                    <div className='flex max-h-[212px] min-w-[180px] flex-col flex-wrap rounded-[4px] bg-paper py-[4px] shadow-8'>
                                      {map(navigation.children, (navigation, navigationIndex) => (
                                        <span
                                          key={navigationIndex}
                                          {...navigation.extraProps}
                                          className='flex whitespace-nowrap bg-transparent py-[8px] px-[12px] hover:bg-secondary'
                                          onClick={(event) => {
                                            hanldeOnClickChildNavigation(
                                              event,
                                              navigation.pathname,
                                            );
                                          }}
                                        >
                                          {navigation.name}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                </Fragment>
                              )}
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </nav>
      )}
      <Link href='/dang-tin-rao' passHref>
        <a
          className={`flex h-[40px] items-center space-x-[4px] rounded-[8px] border bg-transparent p-[12px] ${
            isScrolled ? 'border-primary text-primary' : 'border-paper text-paper'
          }`}
        >
          <span className='font-[600]'>Đăng tin</span>
        </a>
      </Link>
      {/* <button
        type='button'
        className={`relative ${isScrolled ? 'text-primary' : 'text-background'}`}
      >
        <AiOutlineBell className='h-[32px] w-[32px]' />
        <span className='absolute top-[-2px] right-[-2px] flex h-[18px] w-[18px] items-center justify-center rounded-full border border-paper bg-badge text-[10px] leading-[13px] text-paper'>
          5
        </span>
      </button> */}
      <div>
        {currentUser ? (
          <div
            className={`group relative flex cursor-pointer items-center space-x-[8px] ${
              isScrolled ? 'text-text' : 'text-paper'
            }`}
          >
            <div className='h-[32px] w-[32px] overflow-hidden rounded-full'>
              <img
                src={
                  (currentUser.avatar as FileWithFullUrls)?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                alt='avatar'
                className='h-full w-full object-cover'
              />
            </div>
            <span className='font-[600]'>{currentUser.fullName}</span>
            <MdOutlineArrowDropDown className='h-[20px] w-[20px]' />
            <div className='absolute right-0 top-full flex w-max origin-[top_center] scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100'>
              <ul className='mt-[12px] flex w-[200px] flex-col overflow-hidden rounded-[4px] bg-paper py-[4px] shadow-8'>
                {map(userMenu, (userMenuItem, userMenuItemIndex) => (
                  <li key={userMenuItemIndex}>
                    <Link href={userMenuItem.pathname}>
                      <a
                        className={`flex w-full whitespace-nowrap bg-transparent py-[8px] px-[12px] hover:bg-secondary ${
                          userMenuItem.hasBorder ? 'border-b' : null
                        }`}
                      >
                        <span className='font-[500] text-text'>{userMenuItem?.name}</span>
                      </a>
                    </Link>
                  </li>
                ))}
                <li>
                  <button
                    type='button'
                    className='flex w-full whitespace-nowrap bg-transparent py-[8px] px-[12px] hover:bg-secondary'
                    onClick={handleOnSignOut}
                  >
                    <span className='font-[500] text-text'>Đăng xuất</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div
            className={`flex space-x-[4px] font-[600] ${isScrolled ? 'text-text' : 'text-paper'}`}
          >
            <Link href='/dang-ky'>
              <a>
                <span>Đăng ký</span>
              </a>
            </Link>
            <span>/</span>
            <Link href='/dang-nhap'>
              <a>
                <span>Đăng nhập</span>
              </a>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Toolbar;
