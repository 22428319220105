import { map } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { useId } from 'react';
import { BiArea } from 'react-icons/bi';
import { BsBuilding, BsFillCameraVideoFill, BsHouseDoor } from 'react-icons/bs';
import { HiLocationMarker } from 'react-icons/hi';
import { IoCalendarOutline, IoCube } from 'react-icons/io5';
import { TbView360 } from 'react-icons/tb';
import { RealEstate } from '../../../graphql/main/queries/get-real-estates';

interface Props {
  project?: RealEstate;
}

const ProjectCard = ({ project }: Props) => {
  const id = useId();
  const mediaCountObjects = [
    {
      name: '3D Scan',
      icon: <IoCube className='min-h-[13px] min-w-[13px]' />,
    },
    {
      name: 'VR 360',
      icon: <TbView360 className='min-h-[13px] min-w-[13px]' />,
    },
    {
      name: 'Video',
      icon: <BsFillCameraVideoFill className='min-h-[13px] min-w-[13px]' />,
    },
  ];
  const stack = [
    {
      icon: <BiArea className='min-h-[16px] min-w-[16px] text-primary' />,
      value: '1845',
      unit: 'm\u00B2',
    },
    {
      icon: <BsBuilding className='min-h-[16px] min-w-[16px] text-primary' />,
      value: '5',
      unit: 'block',
    },
    {
      icon: <BsHouseDoor className='min-h-[16px] min-w-[16px] text-primary' />,
      value: '286',
      unit: 'căn',
    },
  ];

  return (
    <Link href='#'>
      <a>
        <div
          id={id}
          className='group flex flex-col overflow-hidden rounded-[16px] bg-paper shadow-20 transition-all duration-[500ms] ease-in-out hover:shadow-18'
        >
          <div className='relative'>
            <div className='h-[213px] overflow-hidden'>
              <img
                src='/dummy/card.jpeg'
                alt='project'
                className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
              />
            </div>
            <div className='absolute top-[12px] left-[12px] rounded-full bg-[#6356fe] px-[12px] py-[6px] text-[12px] font-[600] uppercase leading-[15px] text-paper'>
              đang mở bán
            </div>
          </div>
          <div className='flex flex-col space-y-[16px] px-[12px] py-[24px]'>
            <span className='min-h-[23px] text-[18px] font-[600] uppercase leading-[23px] text-accent1 line-clamp-1'>
              Thủy Tiên Sky Villas - Ecopark
            </span>
            <div className='flex min-h-[27px] items-center justify-center space-x-[16px] px-[16px]'>
              {map(mediaCountObjects, (mediaCountObject, mediaCountObjectIndex) => (
                <div
                  key={mediaCountObjectIndex}
                  className='flex items-center space-x-[6px] rounded-full bg-background px-[8px] py-[6px]'
                >
                  {mediaCountObject.icon}
                  <span className='text-[12px] font-[500] leading-[15px] line-clamp-1'>
                    {mediaCountObject.name}
                  </span>
                </div>
              ))}
            </div>
            <div className='flex min-h-[46px] space-x-[10px]'>
              <HiLocationMarker className='min-h-[22px] min-w-[22px]' />
              <span className='leading-[23px] line-clamp-2'>
                1 Đường Võ Nguyên Giáp, Phường Phú Hài, Thành phố Phan Thiết, Bình Thuận
              </span>
            </div>
            <div className='flex min-h-[38px] items-center justify-between space-x-[16px] rounded-[16px] bg-secondary px-[12px] py-[6px]'>
              {map(stack, (stackItem, stackItemIndex) => (
                <div
                  key={stackItemIndex}
                  className='flex w-full min-w-0 items-center justify-center space-x-[8px] rounded-[8px] bg-paper px-[12px] py-[4px]'
                >
                  {stackItem.icon}
                  <span className='line-clamp-1'>
                    {stackItem.value} {stackItem.unit}
                  </span>
                </div>
              ))}
            </div>
            <div className='flex min-h-[20px] items-center justify-between space-x-[24px]'>
              <div className='flex max-w-[50%] items-center space-x-[6px]'>
                <div className='min-h-[20px] min-w-[20px] bg-[url("/icons/price-tag-red.svg")] bg-cover bg-center bg-no-repeat' />
                <span className='w-full truncate text-[16px] font-[600] leading-[20px] text-alert'>
                  15 - 20 triệu/{'m\u00B2'}
                </span>
              </div>
              <div className='flex items-center space-x-[3px]'>
                <IoCalendarOutline className='min-h-[11px] min-w-[11px]' />
                <span>17/06/2021</span>
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default ProjectCard;

export const ProjectCardSkeleton = () => (
  <div className='flex animate-pulse flex-col overflow-hidden rounded-[16px] bg-paper shadow-20'>
    <div className='h-[213px] bg-secondary' />
    <div className='flex flex-col space-y-[16px] px-[12px] py-[24px]'>
      <div className='h-[23px] bg-secondary' />
      <div className='flex items-center justify-center space-x-[16px] px-[16px]'>
        <div className='h-[27px] w-[80px] rounded-full bg-secondary' />
        <div className='h-[27px] w-[80px] rounded-full bg-secondary' />
        <div className='h-[27px] w-[80px] rounded-full bg-secondary' />
      </div>
      <div className='flex space-x-[10px]'>
        <div className='h-[22px] min-w-[22px] bg-secondary' />
        <div className='flex w-full flex-col space-y-[10px]'>
          <div className='h-[18px] bg-secondary' />
          <div className='h-[18px] w-1/2 bg-secondary' />
        </div>
      </div>
      <div className='h-[38px] bg-secondary' />
      <div className='flex items-center justify-between space-x-[24px]'>
        <div className='h-[20px] w-[120px] bg-secondary' />
        <div className='h-[20px] w-[92px] bg-secondary' />
      </div>
    </div>
  </div>
);
