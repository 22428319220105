import ForRentTab from 'components/desktop/home/banner/search-tool/for-rent-tab';
import ForSaleTab from 'components/desktop/home/banner/search-tool/for-sale-tab';
import { useTranslation } from 'hooks';
import { isEqual, map } from 'lodash';
import { useState } from 'react';

const SearchTool = () => {
  const translation = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabs = [
    {
      label: translation.desktop.home.banner.searchTool.forBuy,
      content: <ForSaleTab />,
    },
    {
      label: translation.desktop.home.banner.searchTool.forRent,
      content: <ForRentTab />,
    },
  ];

  const handleOnChangeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  return (
    <div
      id='search-tool'
      className='flex w-full max-w-[1200px] flex-col space-y-[16px] rounded-[16px] bg-[#121b2e]/[.55] p-[24px] shadow-5'
    >
      <div className='flex space-x-[24px]'>
        {map(tabs, (tab, tabIndex) => (
          <button
            type='button'
            key={tabIndex}
            className={`h-[32px] py-[7px] font-[600] text-paper ${
              isEqual(activeTabIndex, tabIndex) ? 'border-b-[2px] border-paper' : ''
            }`}
            onClick={() => {
              handleOnChangeTab(tabIndex);
            }}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {tabs[activeTabIndex].content}
    </div>
  );
};

export default SearchTool;
