import { Popover } from '@headlessui/react';
import { useConfigurationContext } from 'components/providers/configuration-provider';
import { SearchForm1 } from 'forms';
import { useTranslation } from 'hooks';
import { filter, includes, isEmpty, isEqual, map, toString } from 'lodash';
import { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { MdClear, MdOutlineArrowDropDown } from 'react-icons/md';
import { removeVietnameseAccents } from 'utils';

const RealEstateTypePopover = () => {
  const popoverButtonRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const [searchValue, setSearchValue] = useState('');
  const translation = useTranslation();
  const { major } = useConfigurationContext();
  const realEstateTypes = map(
    major?.common?.forSellRealEstateTypes,
    (realEstateType) => realEstateType.key,
  );
  const [privateRealEstateTypes, setPrivateRealEstateTypes] = useState<
    (string | undefined)[] | undefined
  >();
  const form = useFormContext<SearchForm1>();
  const realEstateTypesWatch = useWatch({ control: form.control, name: 'realEstateTypes' });
  const realEstateTypeLabel =
    (translation.major.forSellRealEstateType as any)[toString(realEstateTypesWatch)] ?? 'Tất cả';

  const handleOnChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handleOnClearSearchInput = () => {
    setSearchValue('');
  };
  const handleOnSelectRealEstateType = (realEstateType?: string) => {
    form.setValue('realEstateTypes', realEstateType as any);
    setSearchValue('');
    popoverButtonRef.current?.click();
  };
  const handleOnReset = () => {
    form.setValue('realEstateTypes', undefined);
    setSearchValue('');
    popoverButtonRef.current?.click();
  };

  useEffect(() => {
    setPrivateRealEstateTypes(
      filter(realEstateTypes, (realEstateType) =>
        includes(
          removeVietnameseAccents(
            (translation.major.forSellRealEstateType as any)[toString(realEstateType)],
          ),
          removeVietnameseAccents(searchValue),
        ),
      ),
    );
  }, [searchValue]);
  useEffect(() => {
    setPrivateRealEstateTypes(realEstateTypes);
  }, [major]);

  return (
    <Popover
      id='real-estate-type-popover'
      className='relative flex w-full min-w-0 flex-col space-y-[8px]'
    >
      <div className='flex items-center'>
        <label className='font-[600] text-paper'>Loại BĐS</label>
      </div>
      <Popover.Button
        ref={popoverButtonRef}
        type='button'
        className='flex h-[40px] items-center rounded-[8px] border border-primary bg-paper px-[12px]'
      >
        <span className='w-full truncate text-left'>{realEstateTypeLabel}</span>
        <MdOutlineArrowDropDown className='min-h-[24px] min-w-[24px] text-text2' />
      </Popover.Button>
      <Popover.Panel className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[4px]'>
        <div className='w-[280px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
          <div className='group mx-[16px] flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
            <input
              type='text'
              className='w-full bg-transparent p-[12px] placeholder-text2'
              placeholder='Nhập từ khoá tìm kiếm'
              value={searchValue}
              onChange={handleOnChangeSearchInput}
            />
            {Boolean(searchValue) && (
              <button
                type='button'
                className='ml-[8px] hidden group-hover:flex'
                onClick={handleOnClearSearchInput}
              >
                <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
              </button>
            )}
          </div>
          {isEmpty(privateRealEstateTypes) ? (
            <span className='block h-full px-[16px] italic text-text2'>Không có tuỳ chọn</span>
          ) : (
            <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
              {map(privateRealEstateTypes, (realEstateType, realEstateTypeIndex) => (
                <li key={`${realEstateType}-${realEstateTypeIndex}`}>
                  <button
                    type='button'
                    className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                    onClick={() => {
                      handleOnSelectRealEstateType(realEstateType);
                    }}
                  >
                    <div
                      className={`h-[20px] w-[20px] rounded-full border ${
                        isEqual(realEstateTypesWatch, realEstateType)
                          ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                          : 'border-stroke'
                      }`}
                    />
                    <span className='line-clamp-1'>
                      {(translation.major.forSellRealEstateType as any)[toString(realEstateType)]}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          )}
          <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
            <button
              type='button'
              className='flex items-center justify-center space-x-[6px]'
              onClick={handleOnReset}
            >
              <BsArrowCounterclockwise className='min-h-[16px] min-w-[16px]' />
              <span>Đặt lại</span>
            </button>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default RealEstateTypePopover;
