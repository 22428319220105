import CardList from 'components/desktop/shared/card-list';
import { FileWithFullUrls } from 'graphql/main/queries/get-real-estates';
import { find, isEmpty, map, toString } from 'lodash';
import Link from 'next/link';
import { HomePageContext } from 'pages';
import { useContext } from 'react';
import { AiOutlineTrophy } from 'react-icons/ai';
import { HiLocationMarker } from 'react-icons/hi';
import { IoCallOutline } from 'react-icons/io5';
import { SwiperSlide } from 'swiper/react';

const BrokerCardList = () => {
  const { representativeBrokers } = useContext(HomePageContext).pageData ?? {};

  const handleOnCall = (phoneNumber?: string) => {
    window.open(`tel:${phoneNumber}`, '_self');
  };

  return !isEmpty(representativeBrokers) ? (
    <CardList
      id='broker-card-list'
      spaceBetween={0}
      wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-paper rounded-[16px] space-y-[16px] py-[16px] shadow-1'
      iconComponent={<AiOutlineTrophy className='h-[22px] w-[22px] text-paper' />}
      titleComponent={
        <h2 className='text-[20px] font-[600] leading-[23px]'>Nhà môi giới tiêu biểu</h2>
      }
      cardListComponent={map(
        representativeBrokers,
        (representativeBroker, representativeBrokerIndex) => (
          <SwiperSlide
            key={`${representativeBroker.id}-${representativeBrokerIndex}`}
            className='min-w-[232px] max-w-[232px] px-[8px] py-[16px] xl:min-w-[237px] xl:max-w-[237px]'
          >
            <div className='flex flex-col space-y-[12px] rounded-[8px] bg-paper p-[12px] shadow-23 transition duration-[500ms] ease-in-out hover:shadow-6'>
              <Link href={`/moi-gioi/${representativeBroker.id}`}>
                <a>
                  <div className='flex flex-col space-y-[12px]'>
                    <div className='overflow-hidden rounded-[8px]'>
                      <img
                        src={
                          (representativeBroker.avatar as FileWithFullUrls)?.originalUrl ??
                          '/images/error-image.svg'
                        }
                        alt={representativeBroker.fullName}
                        className='h-[159px] w-full object-cover'
                      />
                    </div>
                    <span className='min-h-[20px] text-[16px] font-[600] capitalize leading-[20px] line-clamp-1'>
                      {representativeBroker.fullName}
                    </span>
                    <div className='flex space-x-[4px] text-text2'>
                      <HiLocationMarker className='min-h-[16px] min-w-[16px]' />
                      <span className='min-h-[20px] text-[14px] capitalize leading-[18px] line-clamp-1'>
                        {representativeBroker.frequentREProvinces}
                      </span>
                    </div>
                  </div>
                </a>
              </Link>
              <button
                className='flex items-center justify-center rounded-[8px] border border-text2 p-[9px]'
                onClick={() => {
                  handleOnCall(toString(find(representativeBroker.phones, (phone) => phone)));
                }}
              >
                <div className='flex items-center space-x-[4px]'>
                  <IoCallOutline className='min-h-[18px] min-w-[18px]' />
                  <span className='font-[600] leading-[18px]'>Liên hệ</span>
                </div>
              </button>
            </div>
          </SwiperSlide>
        ),
      )}
    />
  ) : null;
};

export default BrokerCardList;
