import { useScroll } from 'hooks';
import { gt } from 'lodash';
import HomeLink from './home-link';
import Toolbar from './toolbar';

const Header1 = () => {
  const { position } = useScroll();
  const isScrolled = gt(position, 0);

  return (
    <header
      id='header1'
      className={`fixed top-0 left-0 right-0 z-[50] flex h-[64px] min-w-[1052px] items-center justify-between border-b px-[24px] py-[12px] transition duration-[200ms] ease-in-out ${
        isScrolled ? 'border-transparent bg-paper' : 'border-paper bg-transparent'
      }`}
    >
      <HomeLink />
      <Toolbar />
    </header>
  );
};

export default Header1;
